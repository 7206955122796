import { createSignal, For, Show } from "solid-js";
import { orderBy as _orderBy, startCase as _startCase } from "lodash-es";

export default function HealthDialog() {
	// local
	const [showDialog, setShowDialog] = createSignal(false);
	// refs
	let optionsModal: HTMLElement;

	return (
		<>
			<button
				type="button"
				class="button--transparent"
				onClick={() => setShowDialog(true) && optionsModal.showModal()}
			>
				<svg
					class="fill-red-500"
					xmlns="http://www.w3.org/2000/svg"
					height="1rem"
					viewBox="0 0 512 512"
				>
					<path d="M228.3 469.1L47.6 300.4c-4.2-3.9-8.2-8.1-11.9-12.4h87c22.6 0 43-13.6 51.7-34.5l10.5-25.2 49.3 109.5c3.8 8.5 12.1 14 21.4 14.1s17.8-5 22-13.3L320 253.7l1.7 3.4c9.5 19 28.9 31 50.1 31H476.3c-3.7 4.3-7.7 8.5-11.9 12.4L283.7 469.1c-7.5 7-17.4 10.9-27.7 10.9s-20.2-3.9-27.7-10.9zM503.7 240h-132c-3 0-5.8-1.7-7.2-4.4l-23.2-46.3c-4.1-8.1-12.4-13.3-21.5-13.3s-17.4 5.1-21.5 13.3l-41.4 82.8L205.9 158.2c-3.9-8.7-12.7-14.3-22.2-14.1s-18.1 5.9-21.8 14.8l-31.8 76.3c-1.2 3-4.2 4.9-7.4 4.9H16c-2.6 0-5 .4-7.3 1.1C3 225.2 0 208.2 0 190.9v-5.8c0-69.9 50.5-129.5 119.4-141C165 36.5 211.4 51.4 244 84l12 12 12-12c32.6-32.6 79-47.5 124.6-39.9C461.5 55.6 512 115.2 512 185.1v5.8c0 16.9-2.8 33.5-8.3 49.1z" />
				</svg>
			</button>
			<Show when={showDialog()}>
				<dialog
					ref={optionsModal}
					onClick={({ target }) =>
						target === optionsModal && setShowDialog(false)
					}
					class="backdrop:bg-gray-500 backdrop:bg-opacity-80 rounded-sm max-w-[350px] p-4 text-center"
				>
					<div class="flex flex-col justify-center">
						<button
							type="button"
							class="button is-info mb-4"
							onClick={() => setShowDialog(false)}
						>
							Close
						</button>

						{/* Todo: implement scanning in future */}
						<div class="hidden flex justify-center">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								height="1.5rem"
								viewBox="0 0 512 512"
							>
								<path d="M149.1 64.8L138.7 96H64C28.7 96 0 124.7 0 160V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V160c0-35.3-28.7-64-64-64H373.3L362.9 64.8C356.4 45.2 338.1 32 317.4 32H194.6c-20.7 0-39 13.2-45.5 32.8zM256 192a96 96 0 1 1 0 192 96 96 0 1 1 0-192z" />
							</svg>
						</div>

						<div class="pb-4 border-b-2 border-b-secondary">
							<h4 class="is-size-4">Sweeteners</h4>
							<h5 class="is-size-5">(Best to worst)</h5>
							<For each={sweeteners}>
								{(sweet) => (
									<div class="field">
										<label>
											{sweet.name} (Fructose:{" "}
											{sweet.fructosePercentage}%)
										</label>
										<div class="control">
											<progress
												classList={{
													progress: true,
													[getSweetenerProgressColor(
														sweet.fructosePercentage,
													)]: true,
												}}
												value={sweet.fructosePercentage}
												max="100"
											>
												{sweet.fructosePercentage}%
											</progress>
										</div>
									</div>
								)}
							</For>
						</div>

						<div class="pb-4 border-b-2 border-b-secondary">
							<h4 class="is-size-4">Seed Oils</h4>
							<h5 class="is-size-5">Best (Lower is better)</h5>
							<For each={betterSeedOils}>
								{(oil) => (
									<div class="field">
										<label>
											{oil.name} Oil ({oil.percentage}%)
										</label>
										<div class="control">
											<progress
												classList={{
													progress: true,
													[getSeedOilProgressColor(
														oil.percentage,
													)]: true,
												}}
												value={oil.percentage}
												max="20"
											>
												{oil.percentage}%
											</progress>
										</div>
									</div>
								)}
							</For>
							<h5 class="is-size-5">Worst (Lower is better)</h5>
							<For each={worstSeedOils}>
								{(oil) => (
									<div class="field">
										<label>
											{oil.name} Oil ({oil.percentage}%)
										</label>
										<div class="control">
											<progress
												classList={{
													progress: true,
													[getSeedOilProgressColor(
														oil.percentage,
													)]: true,
												}}
												value={oil.percentage}
												max="100"
											>
												{oil.percentage}%
											</progress>
										</div>
									</div>
								)}
							</For>
						</div>

						<h4 class="is-size-4">MSG</h4>
						<p>Common names for MSG...</p>
						<div class="flex flex-wrap justify-around gap-1">
							<For each={msgNicknames}>
								{(nickname) => (
									<span class="tag is-medium is-primary">
										{nickname}
									</span>
								)}
							</For>
						</div>

						<button
							type="button"
							class="button is-info mt-4"
							onClick={() => setShowDialog(false)}
						>
							Close
						</button>
					</div>
				</dialog>
			</Show>
		</>
	);
}

/**
 * Module Data
 */
const sweeteners = _orderBy(
	[
		{ name: "Glucose", fructosePercentage: 0, sucrosePercentage: 0 },
		{
			name: "Dextrose (D-Glucose)",
			fructosePercentage: 0,
			sucrosePercentage: 0,
		},
		{ name: "Maltose", fructosePercentage: 0, sucrosePercentage: 0 },
		{ name: "Maple Syrup", fructosePercentage: 1, sucrosePercentage: 70 },
		{ name: "Honey", fructosePercentage: 45, sucrosePercentage: 35 },
		{
			name: "High Fructose Corn Syrup",
			fructosePercentage: 55,
			sucrosePercentage: 0,
		},
		{
			name: "Sucrose (Table Sugar)",
			fructosePercentage: 50,
			sucrosePercentage: 0,
		},
		{ name: "Cane Sugar", fructosePercentage: 50, sucrosePercentage: 0 },
		{ name: "Agave Syrup", fructosePercentage: 90, sucrosePercentage: 0 },
		{ name: "Yacon Syrup", fructosePercentage: 35, sucrosePercentage: 35 },
		{
			name: "Brown Rice Syrup",
			fructosePercentage: 5,
			sucrosePercentage: 50,
		},
		{ name: "Date Sugar", fructosePercentage: 5, sucrosePercentage: 75 },
		{
			name: "Blackstrap Molasses",
			fructosePercentage: 5,
			sucrosePercentage: 50,
		},
		{
			name: "Coconut Palm Sugar",
			fructosePercentage: 79,
			sucrosePercentage: 9,
		},
		{ name: "Molasses", fructosePercentage: 30, sucrosePercentage: 25 },
		{ name: "Fructose", fructosePercentage: 100, sucrosePercentage: 0 },
		// todo: move to bad ingredients
		{ name: "Maltodextrin", fructosePercentage: 100, sucrosePercentage: 0 },
	].map((s) => {
		return {
			name: s.name,
			fructosePercentage: s.fructosePercentage + s.sucrosePercentage / 2,
		};
	}),
	["fructosePercentage"],
	["asc"],
);

const betterSeedOils = _orderBy(
	[
		{ name: "Coconut", percentage: 2 },
		{ name: "Macadamia", percentage: 2 },
		{ name: "Cocoa Butter", percentage: 3 },
		{ name: "Palm Kernel", percentage: 3 },
		{ name: "Palm", percentage: 11 },
		{ name: "Avocado", percentage: 12 },
		{ name: "Olive", percentage: 13 },
		{ name: "Linseed", percentage: 15 },
	],
	["percentage"],
	["asc"],
);

const worstSeedOils = _orderBy(
	[
		{ name: "Safflower", percentage: 75 },
		{ name: "Grapeseed", percentage: 71 },
		{ name: "Poppyseed", percentage: 70 },
		{ name: "Sunflower", percentage: 68 },
		{ name: "Hemp", percentage: 60 },
		{ name: "Corn", percentage: 56 },
		{ name: "Soybean", percentage: 55 },
		{ name: "Wheat Germ", percentage: 55 },
		{ name: "Cottonseed", percentage: 53 },
		{ name: "Walnut", percentage: 51 },
		{ name: "Sesame", percentage: 45 },
		{ name: "Rice Bran", percentage: 30 },
		{ name: "Pistachio", percentage: 33 },
		{ name: "Peanut", percentage: 30 },
		{ name: "Canola (Rapeseed)", percentage: 21 },
	],
	["percentage"],
	["desc"],
);

const msgNicknames = [
	"monosodium glutamate",
	"glutamate",
	"glutamic acid",
	"monopotassium glutamate",
	"Calcium Glutamate",
	"Monoammonium Glutamate",
	"Magnesium Glutamate",
	"Natrium Glutamate",
	"Calcium Caseinate",
	"Sodium Caseinate",
	"Yeast Food",
	"Yeast Nutrient",
	"Anything hydrolyzed",
	"Any hydrolyzed protein",
	"hydrolyzed protein",
	"hydrolyzed corn protein",
	"hydrolyzed soy protein",
	"hydrolyzed vegetable protein",
	"Hydrolyzed Beef Stock",
	"Textured Vegetable Protein",
	"Textured Protein",
	"Yeast Extract",
	"autolyzed yeast",
	"autolyzed torula yeast",
	"monosodium salt",
	"monohydrate",
	"L-glutamic acid",
].map(_startCase);

/**
 * Module Functions
 */
function getSweetenerProgressColor(fructosePercentage) {
	if (fructosePercentage >= 60) {
		return "is-danger";
	} else if (fructosePercentage >= 50) {
		return "is-warning";
	} else {
		return "is-success";
	}
}

function getSeedOilProgressColor(percentage) {
	if (percentage >= 50) {
		return "is-danger";
	} else if (percentage >= 20) {
		return "is-warning";
	} else {
		return "is-success";
	}
}
